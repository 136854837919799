<template>
  <div class="regional-rackage">
    <p class="title">支持主流平台出海带货直播</p>
    <div class="container">
      <div class="platform-device">
        <div class="platform">
          <title-underline title="支持平台" />
          <platform-list />
        </div>
        <div class="device">
          <title-underline title="支持设备" />
          <device-list />
        </div>
      </div>

      <div class="area-package">
        <title-underline title="可用地域" />
        <div class="area-list">
          <div
            v-for="(item, index) in areaList"
            :key="item.route"
            class="area-item"
            :class="{ active: activeAreaIndex === index }"
            @click="activeAreaIndex = index"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.route }}</span>
          </div>
        </div>

        <title-underline title="可选套餐" />
        <div class="package-list">
          <div
            v-for="(item, index) in packageList"
            :key="index"
            class="package-item"
            :class="{ active: activePackageIndex === index }"
            @click="activePackageIndex = index"
          >
            <div class="price-hour">
              <span>低至</span>
              <span>￥{{ item.price | priceHour }}</span>
              <span>/小时</span>
            </div>
            {{ item.name }}
          </div>
        </div>

        <title-underline title="使用时长" />
        <div class="duration">
          <div class="moon" @click="duration = 'moon'">
            <a-radio value="moon" :checked="duration === 'moon'">
              <span>按月购买</span>
              <a-input-number v-model="moon" :min="1" />
              <span>月</span>
            </a-radio>
          </div>
          <div class="year" @click="duration = 'year'">
            <a-radio value="year" :checked="duration === 'year'">
              <span>按年购买</span>
              <a-input-number v-model="year" :min="1" />
              <span>年</span>
            </a-radio>
          </div>
        </div>
      </div>
    </div>

    <div class="price-container">
      <div class="total-cost">
        <span class="total">共计</span>
        <span class="price">
          <!-- <span>￥</span> -->
          <span>{{ total }}元</span>
        </span>
        <span v-if="$route.path === '/programme/special-line'" class="tips"
          >（可能要收取硬件设备押金）</span
        >
      </div>
      <div class="start-btn" @click="startNow">立即开启出海直播</div>
    </div>
  </div>
</template>

<script>
import { Radio, InputNumber } from "ant-design-vue";
import TitleUnderline from "@/components/TitleUnderline";
import PlatformList from "../user/purchase/PlatformList";
import DeviceList from "../user/purchase/DeviceList";
// 下面是国旗图片
import America from "@/assets/images/purchase/America.png";
import Thailand from "@/assets/images/purchase/Thailand.png";
import Malaysia from "@/assets/images/purchase/Malaysia.png";
import Japan from "@/assets/images/purchase/Japan.png";
import SouthKorea from "@/assets/images/purchase/SouthKorea.png";
import Singapore from "@/assets/images/purchase/Singapore.png";
import Vietnam from "@/assets/images/purchase/Vietnam.jpg";
import China from "@/assets/images/purchase/China.png";
import Indonesia from "@/assets/images/purchase/Indonesia.png";
import Britain from "@/assets/images/purchase/Britain.png";
// 上面是国旗图片
import { packageList } from "@/request/package";
import { formatCentToYuan } from "@/utils/format";
import { getIsLogin } from "@/utils/utils";
import { checkRealName } from "@/request/user";

export default {
  components: {
    [Radio.name]: Radio,
    [InputNumber.name]: InputNumber,
    TitleUnderline,
    PlatformList,
    DeviceList,
  },
  data() {
    return {
      areaList: [],
      activeAreaIndex: 0,
      activePackageIndex: 0,
      duration: "moon",
      moon: 1,
      year: 1,
      isLogin: false,
      authStatus: undefined,
    };
  },
  props: {
    connectionType: Number,
  },
  watch: {
    activeAreaIndex() {
      this.activePackageIndex = 0;
    },
  },
  computed: {
    packageList() {
      const areaPackage = this.areaList[this.activeAreaIndex];
      return areaPackage ? areaPackage.products || [] : [];
    },
    total() {
      const months = this.duration === "moon" ? 1 * this.moon : 12 * this.year;
      const price = this.packageList[this.activePackageIndex]
        ? this.packageList[this.activePackageIndex].price
        : 0;
      return formatCentToYuan(price * months);
    },
  },
  filters: {
    priceHour(price) {
      return formatCentToYuan(price / 30 / 24);
    },
  },
  created() {
    this.getPackageList();
    this.isLogin = getIsLogin();
    if (this.isLogin) {
      checkRealName().then((res = {}) => {
        const { realNameAuthState } = res;
        this.authStatus = realNameAuthState;
      });
    }
  },
  methods: {
    getPackageList() {
      packageList(this.connectionType).then((res) => {
        for (const item of res) {
          if (item.route.includes("北美")) {
            item.icon = America;
          } else if (item.route.includes("香港")) {
            item.icon = China;
          } else if (item.route.includes("台湾")) {
            item.icon = China;
          } else if (item.route.includes("南美")) {
            item.icon = Indonesia;
          } else if (item.route.includes("英国")) {
            item.icon = Britain;
          } else if (item.route.includes("越南")) {
            item.icon = Vietnam;
          } else if (item.route.includes("新加坡")) {
            item.icon = Singapore;
          } else if (item.route.includes("日本")) {
            item.icon = Japan;
          } else if (item.route.includes("马来西亚")) {
            item.icon = Malaysia;
          } else if (item.route.includes("泰国")) {
            item.icon = Thailand;
          } else if (item.route.includes("韩国")) {
            item.icon = SouthKorea;
          } else if (item.route.includes("美国")) {
            item.icon = America;
          } else if (item.route.includes("印尼")) {
            item.icon = Indonesia;
          }
        }
        this.areaList = res || [];
      });
    },
    startNow() {
      if (!this.isLogin) {
        this.$router.push("/login");
      } else if (this.authStatus === 1) {
        this.$router.push({
          path: "/user/purchase",
          query: {
            connectionType: this.connectionType,
          },
        });
      } else {
        this.$router.push("/user/info");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.regional-rackage {
  width: 100%;
  height: 1430px;
  background: url("../../assets/images/programme/overseas-regional-package.jpg")
    center no-repeat;
  background-size: cover;
  padding-top: 79px;

  .title {
    color: #39425c;
    font-size: 44px;
    text-align: center;
    -moz-outline-radius-bottomleft: 59px;
    font-weight: bold;
  }

  .container {
    width: 1440px;
    margin: 0 auto;

    .platform-device {
      display: flex;
      justify-content: space-between;
      background-color: rgba(255, 255, 255, 0);
      margin-bottom: 10px;
      .device,
      .platform {
        width: 715px;
        height: 410px;
        background-color: rgba(255, 255, 255, 0.6);
      }

      .platform {
        padding: 40px 88px 0 85px;
      }
      .device {
        padding: 40px 60px 0 60px;
      }
    }

    .area-package {
      padding: 30px 80px 90px 80px;
      background-color: rgba(255, 255, 255, 0.6);
      /deep/ .title-underline {
        display: inline-block;
        margin-bottom: 24px;
      }
      .area-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 39px;
        .area-item {
          width: 180px;
          height: 60px;
          background-color: #ffffff;
          border: 1px solid #e9f0ff;
          margin-bottom: 10px;
          margin-left: -1px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 44px;
            height: 30px;
            object-fit: contain;
            margin-right: 8px;
          }
          span {
            color: #39425c;
            font-size: 18px;
          }
          &.active {
            background-color: #ecf2ff;
            border: 1px solid #3661eb;
            position: relative;
            font-weight: bold;
            z-index: 2;
            span {
              color: #3661eb;
            }
          }
        }
      }

      .package-list {
        display: flex;
        margin-bottom: 39px;
        .package-item {
          width: 250px;
          height: 60px;
          margin-top: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          border: 1px solid #e9f0ff;
          font-size: 18px;
          position: relative;
          .price-hour {
            position: absolute;
            width: 250px;
            height: 60px;
            background-color: #fc9415;
            top: -60px;
            padding-bottom: 16px;
            justify-content: center;
            align-items: flex-end;
            display: none;
            span {
              color: #ffffff;
              font-weight: bold;
              &:nth-child(1),
              &:nth-child(3) {
                font-size: 18px;
              }
              &:nth-child(2) {
                font-size: 32px;
                margin-bottom: -3px;
              }
            }
          }
          &.active {
            color: #ffffff;
            font-weight: Bold;
            background-color: #3661eb;
            border: 0;
            .price-hour {
              display: flex;
            }
          }
        }
      }

      .duration {
        width: 1252px;
        height: 120px;
        display: flex;
        justify-content: space-between;
        .moon,
        .year {
          width: 622px;
          height: 100%;
          background-color: #e3eafa;
          display: flex;
          align-items: center;
          justify-content: center;
          :deep(.ant-input-number) {
            margin: 0 10px;
          }
          span {
            color: #39425c;
            font-size: 18px;
          }
        }
      }
    }
  }

  .price-container {
    width: 1440px;
    height: 150px;
    background-color: #ffffff;
    margin: 0 auto 30px auto;
    padding: 0 80px;
    position: relative;

    .start-btn {
      width: 320px;
      height: 44px;
      line-height: 44px;
      border-radius: 22px;
      background-color: #3661eb;
      text-align: center;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
      position: absolute;
      bottom: 23px;
      left: 50%;
      margin-left: -160px;
    }

    .total-cost {
      height: 90px;
      display: flex;
      align-items: center;
      .total {
        color: #39425c;
        font-size: 18px;
        margin-right: 21px;
      }
      .price {
        // display: flex;
        // align-items: flex-end;
        & > span {
          color: #f47755;
          font-weight: bold;
          // &:first-child {
          //   font-size: 24px;
          // }
          // &:last-child {
          //   font-size: 36px;
          // }
          font-size: 24px;
        }
      }
      .tips {
        color: #cccccc;
        font-size: 18px;
      }
    }
  }
}
</style>
