<template>
  <div class="three-steps">
    <p class="title">出海直播三步走</p>
    <div class="three-steps-container">
      <div class="step-imgs">
        <img class="step1" src="@/assets/images/programme/step1.png" />
        <i class="iconfont icon-jiantou_xiangyouliangci"></i>
        <img class="step2" src="@/assets/images/programme/step2.png" />
        <i class="iconfont icon-jiantou_xiangyouliangci"></i>
        <img class="step3" src="@/assets/images/programme/step3.png" />
      </div>

      <div class="steps">
        <div class="item">
          <img src="@/assets/images/programme/下载.png" />
          <span>①下载</span>
          <span>下载并安装客户端</span>
        </div>
        <div class="item">
          <img src="@/assets/images/programme/登录.png" />
          <span>②登录</span>
          <span>登录账号一键连接</span>
        </div>
        <div class="item">
          <img src="@/assets/images/programme/使用.png" />
          <span>③使用</span>
          <span>打开直播app，开启直播</span>
        </div>
      </div>
    </div>
    <!-- <img class="left-phone" src="@/assets/images/programme/three-steps.png" /> -->
    <!-- <div class="steps">
      <div class="item">
        <img src="@/assets/images/programme/下载.png" />
        <span>①下载</span>
        <span>下载并安装客户端</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/登录.png" />
        <span>②登录</span>
        <span>登录账号一键连接</span>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/使用.png" />
        <span>③使用</span>
        <span>打开直播app，开启直播</span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.three-steps {
  height: 750px;
  background: url("../../../assets/images/programme/overseas-client-bg3.jpg")
    center no-repeat;
  background-size: cover;
  padding-top: 55px;
  position: relative;
  display: flex;
  flex-direction: column;

  .title {
    color: #39425c;
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 13px;
    text-align: center;
  }

  .three-steps-container {
    width: 1440px;
    height: 620px;
    margin: 0 auto;
    display: flex;

    .step-imgs {
      flex: 1;
      margin-right: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .step1 {
        width: 168px;
      }
      .step2 {
        width: 278px;
      }
      .step3 {
        width: 321px;
      }
      .icon-jiantou_xiangyouliangci {
        font-size: 40px;
        color: #3661eb;
      }
    }

    .steps {
      height: 100%;
      width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        width: 100%;
        height: 200px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        &:nth-child(1) {
          img {
            width: 151px;
            margin-bottom: 15px;
          }
        }
        &:nth-child(2) {
          img {
            width: 152px;
            margin-bottom: 15px;
          }
        }
        &:nth-child(3) {
          img {
            width: 156px;
            margin-bottom: 15px;
          }
        }
        span {
          &:first-of-type {
            color: #3661eb;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          &:last-of-type {
            color: #39425c;
            font-size: 18px;
          }
        }
      }
    }
  }

  // .left-phone {
  //   width: 456px;
  //   position: absolute;
  //   top: 92px;
  //   left: 264px;
  // }

  // .steps {
  //   display: flex;
  //   margin-right: 238px;
  //   margin-left: auto;
  //   .item {
  //     width: 300px;
  //     height: 360px;
  //     background-color: rgba(255, 255, 255, 0.5);
  //     margin-left: 20px;
  //     border-radius: 10px;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     &:nth-child(1) {
  //       padding-top: 41px;
  //       img {
  //         width: 277px;
  //         margin-bottom: 17px;
  //       }
  //     }
  //     &:nth-child(2) {
  //       padding-top: 47px;
  //       img {
  //         width: 276px;
  //         margin-bottom: 13px;
  //       }
  //     }
  //     &:nth-child(3) {
  //       padding-top: 49px;
  //       img {
  //         width: 279px;
  //         margin-bottom: 15px;
  //       }
  //     }

  //     span {
  //       &:first-of-type {
  //         color: #3661eb;
  //         font-size: 24px;
  //         font-weight: bold;
  //         margin-bottom: 20px;
  //       }
  //       &:last-of-type {
  //         color: #39425c;
  //         font-size: 18px;
  //       }
  //     }
  //   }
  // }
}
</style>
