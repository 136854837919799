<template>
  <div class="client-advantage">
    <p class="title">e路播客户端优势</p>
    <div class="list">
      <div class="item">
        <img src="@/assets/images/programme/稳定.png" />
        <span>稳定网络</span>
        <p>提供急速、好用、安全、稳定、放心的专线出海通道。</p>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/安全.png" />
        <span>安全合规</span>
        <p>
          依托国家数据跨境试点，拥有多年互联网安全经验，赋能大数据，完善的网络白名单机制，减少企业内部合规风险，全面尊重客户数据主权，有效保护直播企业出海业务安全。
        </p>
      </div>
      <div class="item">
        <img src="@/assets/images/programme/全球.png" />
        <span>全球覆盖</span>
        <p>
          覆盖全球的数据中心，提供伦敦、马尼拉、东京、胡志明、新加坡、曼谷、华盛顿、迪拜、法兰克福等地域线路供用户选择。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.client-advantage {
  width: 100%;
  height: 770px;
  background: url("../../../assets/images/programme/client-advantage.jpg")
    center no-repeat;
  background-size: cover;
  padding-top: 79px;

  .title {
    color: #39425c;
    font-size: 44px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 57px;
  }

  .list {
    width: 1440px;
    height: 400px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .item {
      width: 420px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 24px;
      padding-right: 24px;
      &:nth-child(1) {
        padding-top: 28px;
        img {
          width: 94px;
        }
      }
      &:nth-child(2) {
        padding-top: 28px;
        img {
          width: 181px;
        }
      }
      &:nth-child(3) {
        padding-top: 14px;
        img {
          width: 103px;
        }
      }
      img {
        margin-bottom: 9px;
      }
      span {
        color: #3661eb;
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 38px;
      }
      p {
        color: #39425c;
        font-size: 18px;
        line-height: 36px;
        text-indent: 2em;
      }
    }
  }
}
</style>
