/*
  用户登录
*/
import service from "./index";

const mainUrl = "customer";

/**
 * @description 登录
 */
export function login(params) {
  return service.post(`${mainUrl}/login`, params);
}

/**
 * @description 发送短信验证码
 */
export function smsCode(params) {
  return service.get(`admin/user/sms-code`, { params });
}

/**
 * @description 注册
 */
export function register(params) {
  return service.post(`${mainUrl}/register`, params);
}

/**
 * @description 用户通过手机号修改密码
 */
export function changePwdPhone(params) {
  return service.post(`${mainUrl}/change/pwd/phone`, params);
}

/**
 * @description 查看是否实名
 */
export function checkRealName() {
  return service.get(`${mainUrl}/checkRealName`);
}

/**
 * @description 企业认证
 */
export function cert(data) {
  return service.post(`${mainUrl}/cert`, data);
}

/**
 * @description 企业认证
 */
export function personalAuth(params) {
  return service.get(`${mainUrl}/real-name-auth`, {
    params,
  });
}

/**
 * @description 我的账户
 */
export function myAccount() {
  return service.get(`${mainUrl}/account`);
}

/**
 * @description 修改密码
 */
export function updatePwd(data) {
  return service.post(`${mainUrl}/updatePwd`, data);
}

/**
 * @description 忘记密码
 * */
export function forgetPwd(data) {
  return service.post(`${mainUrl}/forget`, data);
}

/**
 * @description 当前用户套餐
 * */
export function userPackage(params) {
  return service.get("/web/product/customer-list", { params });
}

/**
 * @description 用户订单查询
 * */
export function userOrder(params) {
  return service.get("admin/order/list", { params });
}
