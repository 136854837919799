<template>
  <div class="client">
    <div class="client-title">
      <div class="client-title-text">
        <p>全球直播</p>
        <p>GLOBAL LIVE BROADCAST</p>
        <p>领先一步，高性价比解决方案</p>
      </div>
    </div>

    <live-across-the-sea />

    <three-steps />

    <regional-package :connection-type="connectionType" />

    <advantage-comparison />

    <client-advantage />
  </div>
</template>

<script>
import LiveAcrossTheSea from "./client/LiveAcrossTheSea";
import ThreeSteps from "./client/ThreeSteps";
import RegionalPackage from "./RegionalPackage";
import AdvantageComparison from "./client/AdvantageComparison";
import ClientAdvantage from "./client/ClientAdvantage";
import { ConnectionTypeEnum } from "../../config/system";

export default {
  components: {
    LiveAcrossTheSea,
    ThreeSteps,
    RegionalPackage,
    AdvantageComparison,
    ClientAdvantage,
  },
  data() {
    return {
      connectionType: ConnectionTypeEnum.Vpn,
    };
  },
};
</script>

<style lang="less" scoped>
.client {
  width: 100%;
  margin-top: -24px;
  margin-bottom: -24px;
  .client-title {
    width: 100%;
    height: 750px;
    background: url("../../assets/images/programme/overseas-client.jpg")
      no-repeat;
    background-position: 0px 0px;
    // background-size: cover;
    background-size: 100%;
    overflow: hidden;
    .client-title-text {
      padding-top: 115px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      position: relative;
      p {
        text-align: center;
        width: 100%;
        font-size: 80px;
        margin-bottom: 0;
        font-weight: bold;
        color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        &:nth-child(1) {
          top: 115px;
          color: #ffffff;
        }
        &:nth-child(2) {
          top: 265px;
          background: url("../../assets/images/programme/overseas-client.jpg")
            no-repeat;
          background-position: 0px -265px;
          // background-size: cover;
          background-size: 100%;
          -webkit-background-clip: text;
        }
        &:nth-child(3) {
          top: 450px;
          font-size: 44px;
          font-weight: 400;
          letter-spacing: 44px;
          color: #ffffff;
          padding-left: 44px;
        }
      }
    }
  }
}
</style>
