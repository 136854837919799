<template>
  <div class="live-across-the-sea">
    <div class="live">
      <div class="live-content">
        <p class="title">随时随地，享受快速稳定的跨海直播</p>
        <p class="intro">
          跨海直网络缓慢难以忍受？不用担心，我们目前在全球支持 80 个国家，170 个
          服务器位置，拥有超过 3000
          台服务器，并且还在持续地拓展服务。确保无论您从何处进行连接，都始终能在附近能找到高带宽服务器且低延迟的连接，享受最佳的性能。
        </p>
        <div class="live-list">
          <div class="platform-item">
            <div class="img-container">
              <img src="@/assets/images/home/TikTok.jpg" />
            </div>
            <span>TikTok</span>
          </div>
          <div class="platform-item">
            <div class="img-container">
              <img src="@/assets/images/home/Amazon.jpg" />
            </div>
            <span>Amazon</span>
          </div>
          <div class="platform-item">
            <div class="img-container">
              <img src="@/assets/images/home/Facebook.jpg" />
            </div>
            <span>Facebook</span>
          </div>
          <div class="platform-item">
            <div class="img-container">
              <img src="@/assets/images/home/AliExpress.jpg" />
            </div>
            <span>AliExpress</span>
          </div>
          <div class="platform-item">
            <div class="img-container">
              <img src="@/assets/images/home/Instagram.jpg" />
            </div>
            <span>Instagram</span>
          </div>
          <div class="platform-item">
            <div class="img-container">
              <img src="@/assets/images/home/Shopee.jpg" />
            </div>
            <span>Shopee</span>
          </div>
          <div class="ellipsis">
            <div class="ellipsis-item"></div>
            <div class="ellipsis-item"></div>
            <div class="ellipsis-item"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="tech-support">
      <div class="tech-support-list">
        <div class="item">
          <span>安全合规</span>
        </div>
        <div class="item">
          <span>提供全球技术支持</span>
        </div>
        <div class="item">
          <span>随时随地</span>
        </div>
        <div class="item">
          <span>不限带宽 无限流量</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.live-across-the-sea {
  width: 100%;

  .live {
    width: 100%;
    height: 750px;
    background: url("../../../assets/images/programme/overseas-client-bg1.jpg")
      center no-repeat;
    background-size: cover;
    padding-top: 161px;
    .live-content {
      width: 850px;
      margin-right: 237px;
      float: right;
      .title {
        color: #39425c;
        font-size: 44px;
        font-weight: bold;
        margin-bottom: 45px;
      }
      .intro {
        color: #39425c;
        font-size: 24px;
        font-weight: bold;
        text-indent: 2em;
        line-height: 36px;
        margin-bottom: 62px;
      }
      .live-list {
        display: flex;
        .platform-item {
          margin-right: 45px;
          position: relative;
          .img-container {
            width: 80px;
            height: 80px;
            border-radius: 10px;
            box-shadow: 0px 6px 6px 0px rgba(0, 68, 142, 0.24);
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          span {
            color: #39425c;
            font-size: 18px;
            font-weight: bold;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -34px;
          }
        }
        .ellipsis {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          .ellipsis-item {
            width: 11px;
            height: 11px;
            background-color: #ffffff;
            border-radius: 50%;
            margin-right: 14px;
          }
        }
      }
    }
  }

  .tech-support {
    width: 100%;
    height: 300px;
    background: url("../../../assets/images/programme/overseas-client-bg2.jpg")
      center no-repeat;
    background-size: cover;
    padding: 3px 0 6px 0;
    .tech-support-list {
      width: 1440px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .item {
        width: 245px;
        height: 100%;
        background: url("../../../assets/images/programme/tech-support.png")
          center no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
          color: #ffffff;
          font-size: 24px;
          line-height: 40px;
          display: block;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
